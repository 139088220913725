
.menubar {
  height: 60px;
  display: flex;
  justify-content: center;
  background: #9f1854; //esaic2023 color

  .menubar-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 20px;
    color: #ffc75d; //esaic2023 color
    text-decoration: none;
    margin: 0 auto;

    .text {
      font-size: 0.8rem;
      display: block;
      margin-top: -15px;
    }

    .menubar-item-icon {
      font-size: 1.6rem;
      display: block;
    }

    .icon-sponsor, .icon-aussteller {
      width: 40px;
      font-size: 1.6rem !important;
      margin: 8px 0;
    }

    .icon-aussteller-new {
      width: 50px;
      font-size: 1.6rem !important;
      background-size: 60px 60px !important;
    }
  }
}
