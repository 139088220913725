

ion-content {
  //--padding-start: env(safe-area-inset-left); /* Apply safe area */
  //--padding-end: env(safe-area-inset-right);
}

.docu-footer {
  background: white !important;
  border-top: 1px solid lightgrey;

  .footer-container {
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center; //center
    padding-right: 10px;



    .footer-link {
      color: #274564 !important;
      margin: 1px 5px 2px 5px;
      font-weight: 400;
      font-size: 0.9rem;
      text-decoration: none;
    }
  }

}

 ion-progress-bar {
    --background: #274564 ;
    --buffer-background: #274564 ;
  }



