

#fullbanner {
  position: absolute;
  background-color: white !important;
  z-index: 1040; //999;
  width: 100%;
  height: 100%;

}

.banner-wrapper {
  height: 100px;
  color: #000;
  width: 100%;

  .swiper-no-swiping {
    img {
      padding: 5px;
    }
  }
}

@media (max-width: 768px) {
  .banner-wrapper {
    height: 80px;
    color: #000;
    width: 100%;

    .swiper-no-swiping {
      img {
        padding: 5px;
      }
    }
  }
}
