
.branding-intro {
  width: 100%;
  max-width: 600px;
  max-height: 30px;
}

#branding-intro-logo-image path {

}

#branding-intro-logo-text path {
  stroke: #fff;
  stroke-dasharray: 2200;
  stroke-dashoffset: 1000;
  animation: dash-text 2s ease-out forwards 1;
  transition: fill .2s ease-out;
}


