
$conf-color: #10528E;
.branding-wrapper {
  color: #fff !important;
  //background: url(../img/splash-bg.jpg) no-repeat center center fixed;
  background-color: $conf-color !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  --background: -moz-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* FF3.6-15 */
  --background: -webkit-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  --background: linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333F6A88', endColorstr='#336D8CA2', GradientType=1); /* IE6-9 fallback on horizontal gradient */


  .branding-intro {
    width: 100%;
    max-width: 600px;
    max-height: 30px;
  }

  #branding-intro-logo-image path {

  }

  #branding-intro-logo-text path {
    stroke: #fff;
    stroke-dasharray: 2200;
    stroke-dashoffset: 1000;
    animation: dash-text 2s ease-out forwards 1;
    transition: fill .2s ease-out;
  }
}
